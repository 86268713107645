((myDoc)=>{

    const limit = 10;
    var start = 20;
    var reachMax = false;
    var docHeight = 0;
    var sort = '';
    var borough = [];
    var price = [];
    var nationality = [];
    var hair_colour = [];
    var services = [];
    var location = '';
    var category = '';
    let filterInput =  myDoc.querySelectorAll('.filter-list input');
    const galleryRow = myDoc.querySelector('.gallery-row'); // Use querySelector
    const counter = myDoc.querySelector('.counter');
    const locationdiv = myDoc.querySelector('div.location');
    const categorydiv = myDoc.querySelector('div.category');
    const clearFilter = myDoc.querySelector('.clear_filter');




    if(locationdiv){
        //console.log(locationdiv);
        location = locationdiv.innerHTML;
    }

    if(categorydiv){
        //console.log(categorydiv);
        category = categorydiv.innerHTML;
    }

    if (galleryRow) { // Check if the element exists
        window.addEventListener('scroll', () => { // Use addEventListener
            let less = 2200;
            if (galleryRow.scrollHeight <= 2200) {
                less = 0;
            }
            if (window.scrollY > galleryRow.scrollHeight - less) { // Use window.scrollY
                if (docHeight < 1) {
                    docHeight = 1;
                    getStaff();
                }
            }
        });
    }

    function getStaff() {
        // const loader = document.querySelector(".showLoader"); // Select loader
        // if (loader) {
        //     loader.classList.remove('d-none'); // Remove loader
        // }

        if (reachMax) {
            return false;
        } else {
            fetch(filterUrl, { // Use fetch API
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Important for POST data
                'X-CSRF-TOKEN': csrfToken // Add CSRF token if needed
                },
                body: new URLSearchParams({ // Use URLSearchParams for form data
                    limit: limit,
                    skip: start,
                    sort: sort,
                    borough: borough,
                    prices: price,
                    nationality: nationality,
                    hair_colour: hair_colour,
                    services: services,
                    location: location,
                    category: category,
                }),
            })
            .then(response => response.json()) // Parse JSON response
            .then(list => {
                //console.log(list);

                // if (loader) {
                //     loader.classList.add('d-none'); // Remove loader
                // }


                if (list && list.html) { //Check if list and list.html exists
                    //console.log(galleryRow);
                    galleryRow.insertAdjacentHTML('beforeend', list.html); // Use insertAdjacentHTML
                    counter.innerHTML = list.count;
                    start += limit;
                    docHeight = 0;
                } else {
                    if (start < 1) {
                        galleryRow.innerHTML = `
                            <div class="col-lg-12">
                            <div class="alert alert-warning" role="alert">
                                No Models Found!
                            </div>
                            </div>
                        `;
                        counter.innerHTML = list.count;
                    }
                    reachMax = true;
                    return false;
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error); // Handle errors
            });
        }
    }





    const getArrayInputValues = (inputName) => {
        const inputElements = myDoc.querySelectorAll(`input[name="${inputName}"]`);
        let filterval = [];
        inputElements.forEach(input => {
            if (input.checked) {
                if (!filterval.includes(input.value)) {
                    filterval.push(input.value);
                }
            } else {
                filterval = filterval.filter(value => value !== input.value);
            }
        });
        //console.log(filterval);
        return filterval;
    }

    filterInput.forEach(item=>{
        item.addEventListener('input', (e)=>{
            let inputName = e.target.name;
            if(e.target.classList.contains('price')){
                //console.log(e.target);
                price = getArrayInputValues(inputName);
                //console.log(price);
            }
            if(e.target.classList.contains('nationality')){
                //console.log(e.target);
                nationality = getArrayInputValues(inputName);
                //console.log(nationality);
            }
            if(e.target.classList.contains('hair_color')){
                //console.log(e.target);
                hair_colour = getArrayInputValues(inputName);
                //console.log(hair_colour);
            }
            if(e.target.classList.contains('service')){
                //console.log(e.target);
                services = getArrayInputValues(inputName);
                //console.log(services);
            }

            // console.log({
            //     price,
            //     nationality,
            //     hair_colour,
            //     services
            // });
            clearFilter.classList.remove('d-none');
            galleryRow.innerHTML = ``;
            start = 0;
            reachMax = false;
            docHeight = 0;
            getStaff();
        })
    });

    const addRemoveActiveOnclick = (ele) => {
        if(ele) {
            let targetEle = myDoc.querySelector(ele),
            actinArray = targetEle.querySelectorAll('li'),
            clearBorough = targetEle.querySelector('.btn-clearBorough');
            let hasActive = false;
            //let clearBorough = myDoc.querySelector(ele);
            actinArray.forEach(i => {
                i.addEventListener('click', (e)=> {
                    e.target.classList.toggle('active');
                    if(e.target.classList.contains('active')){
                        borough.push(i.dataset.slug);
                    }else{
                        borough = borough.filter(s => s !== i.dataset.slug);
                    }
                    galleryRow.innerHTML = ``;
                    start = 0;
                    reachMax = false;
                    docHeight = 0;
                    //console.log(borough)
                    getStaff();

                    hasActive = Array.from(actinArray).some(item => item.classList.contains('active'));
                    //console.log(hasActive);
                    hasActive?clearBorough.classList.remove('d-none'):clearBorough.classList.add('d-none');

                })
            })

            clearBorough.addEventListener('click', (e) => {
                actinArray.forEach(item => {
                    item.classList.contains('active')?item.classList.remove('active'):null;
                });
                setTimeout(()=>{e.target.classList.add('d-none')}, 100)
                galleryRow.innerHTML = ``;
                start = 0;
                reachMax = false;
                docHeight = 0;
                borough = [];
                //console.log(borough)
                getStaff();
            })
        }
    }
    //addRemoveActiveOnclick('.dropdown-borough')







    clearFilter.addEventListener('click', () => {
        const checkInput = myDoc.querySelectorAll(`input[type="checkbox"]`);
        //const sortList = '';
        galleryRow.innerHTML = '';
        checkInput.forEach(input => {
            if (input.checked) {
                input.checked = false;
            }
        });

        const recommendedOption = myDoc.querySelector(`input[data-name="desk-sortby"][value=""]`);
        if (recommendedOption) {
            recommendedOption.checked = true;
        }

        const recommendedMobileOption = myDoc.querySelector(`input[data-name="mobile-sortby"][value=""]`);
        if (recommendedMobileOption) {
            recommendedMobileOption.checked = true;
        }


        const sortDropdown = myDoc.querySelector('.dropdown-sortby .btn span');
        if (sortDropdown) {
            sortDropdown.innerHTML = recommendedOption ? recommendedOption.dataset.value : "Recommended";
        }

        clearFilter.classList.add('d-none');

        sort = '';
        price = [];
        nationality = [];
        hair_colour = [];
        services = [];
        location = '';
        category = '';
        reachMax = false;
        start = 0;
        getStaff();
    });

    const syncSort = (sourceName, targetName) => {
        myDoc.querySelectorAll(`input[data-name="${sourceName}"]`).forEach((radio) => {
            radio.addEventListener("change", function (e) {
                // myDoc.querySelectorAll(`input[data-name="${targetName}"]`).forEach((targetRadio) => {
                //     if (targetRadio.value === this.value) {
                //         targetRadio.checked = true;
                //     }
                // });

                if(sourceName === 'desk-sortby') {
                    e.target.closest('.dropdown-sortby').querySelector('.btn span').innerHTML = e.target.dataset.value;
                    sort = e.target.value;
                    galleryRow.innerHTML = ``;
                    start = 0;
                    reachMax = false;
                    docHeight = 0;
                    console.log(e.target.value);

                    getStaff();
                }

                clearFilter.classList.remove('d-none');

            });
        });
    }
    syncSort('desk-sortby', 'mobile-sortby');
    // syncSort('mobile-sortby', 'desk-sortby');



})(document);
